import React, {useState, useRef, useEffect} from "react";
import axios from "axios";
import styled from 'styled-components';
import  {Col, Row,} from "reactstrap";
import BoutonJaune from "../DesignSystem/BoutonJaune";
import {SelectFilters,TextareaStyled} from "../DesignSystem/Commons/Elements";
import {Legal} from "../DesignSystem/Commons/Containers";
import { FormConnect,InputStyled } from "../DesignSystem/Commons/Forms";
import BoutonJauneInverseHP from "../DesignSystem/BoutonJauneInverse";
import { ButFermer } from "../DesignSystem/PopUp";
import {IoCloseCircleOutline} from "react-icons/io5";
import {RowStyledForm, ButsForm} from "../DesignSystem/Commons/Containers";
import BoutonRoseHP from "../DesignSystem/BoutonRose";
import SpinnerHP from '../Spinners/Spinner1';
import PopErrorHP from "../Popups/PopError";




export function FormOubliHP({onClick,title, className}) {
    const formRef = useRef();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [titrePop, setTitrePop] = useState(null);
    const [txt, setTxt] = useState(null);
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    
const [form, setForm] = useState({
    Nom:"",
    Prenom:"",
    Mail:"",
    Identifiant:""
});

const handleEmailChange = (e) => {
  const email = e.target.value;
  setForm({ ...form, Mail: email });

  // Email regex pattern
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (!emailPattern.test(email)) {
    setTxt('Veuillez saisir une adresse email valide');
    setTitrePop('ERREUR');
  } else {
    setTxt('');
    setTitrePop('');
  }
};
    const handleOubliID = (event) => {
    if (!form.Nom || !form.Prenom || !form.Mail) {
      setIsErrorVisible(true);
      setTxt('Veuillez remplir tous les champs');
      setTitrePop('ERREUR');
      return;
    }
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (!emailPattern.test(form.Mail)) {
    setIsErrorVisible(true);
    setTxt('Veuillez saisir une adresse email valide');
    setTitrePop('ERREUR');
  } else {
    setTxt('');
    setTitrePop('');
  }
setLoading(true);
   axios.post("/users/connexionoublieid",form).then(res => {
    setIsErrorVisible(true);
    setTxt(`Un Email vous a été envoyé sur votre boite mail ${form.Mail}`);
    setTitrePop('Identifiant oublié');
      setLoading(false);
      setForm({
        Nom:"",
        Prenom:"",
        Mail:"",
      });
      // if(typeof onClick === "function") {
      //   onClick();
      //  } 
    
      }).catch(err => {
        console.log(err); 
      setIsErrorVisible(true);
      setTxt("Vos informations ne correspondent pas à nos enregistrements");
      setTitrePop("ERREUR");
      setLoading(false);
      }
      )
      
    };
    const handleOubliPass = (event) => {
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!emailPattern.test(form.Mail)) {
        setIsErrorVisible(true);
        setTxt('Veuillez saisir une adresse email valide');
        setTitrePop('ERREUR');
      } else {
        setTxt('');
        setTitrePop('');
      }
      setLoading(true);
      axios.post("/users/connexionoubliepass",form).then(res => {
        setIsErrorVisible(true);
        setTxt(`Un Email vous a été envoyé pour réinitialiser votre mot de passe sur votre boite mail : ${form.Mail}`);
        setTitrePop('Changement de mot de passe');
        setLoading(false);
        setForm({
          Nom:"",
          Prenom:"",
          Mail:"",
          Identifiant:""
        });
        //  if(typeof onClick === "function") {
        //   onClick();
        //  } 
      
         }).catch(err => {
          console.log(err); 
          setIsErrorVisible(true);
          setTxt("Vos informations ne correspondent pas à nos enregistrements");
          setTitrePop("ERREUR");
          setLoading(false);
         }
         )
         
       };

    // useEffect(() => {
    //     function handleClickOutside(event) {
    //       if (formRef.current && !formRef.current.contains(event.target)) {
    //         onClick();
    //       }
    //     }
  
    //     document.addEventListener('mouseup', handleClickOutside);

    //     return () => {
    //       document.removeEventListener('mouseup', handleClickOutside);
    //     };
    //   }, [onClick]);

        return(
        <>
         {isErrorVisible && <PopErrorHP message={txt} titre={titrePop} onClick={() => setIsErrorVisible(false)} />}
        {loading && <SpinnerHP/>}
        <RowStyledForm className={className} ref={formRef}>
        <ButFermer onClick={onClick}><IoCloseCircleOutline/></ButFermer>
             <div>
                <FormConnect className="maxWidth" >
                    {
                        className === "mdp" ?
                        <InputStyled id=""
                        name="Identifiant"
                        onChange={(e) => setForm({...form, Identifiant: e.target.value})}
                        placeholder="Identifiant"
                        type="text"
                        value={form.Identifiant}
                        />
                        :""
                    }
                    <InputStyled id=""
                        name="Nom"
                        placeholder="Nom (obligatoire)"
                        onChange={(e) => setForm({...form, Nom: e.target.value})}
                        type="text"
                        value={form.Nom}
                        />
                    <InputStyled id=""
                        name="Prenom"
                        onChange={(e) => setForm({...form, Prenom: e.target.value})}
                        placeholder="Prénom (obligatoire)"
                        type="text"
                        value={form.Prenom}
                        />
                    <InputStyled id=""
                        name="Mail"
                        onChange={(e) => setForm({...form, Mail: e.target.value})}
                        placeholder="Email (obligatoire)"
                        type="email"
                        value={form.Mail}
                        />
                </FormConnect>

                 <ButsForm>
                    <BoutonJaune className={"check"} onClick={className === "identifiant" ? handleOubliID : handleOubliPass}  libellé="Valider"></BoutonJaune>
                    <BoutonJauneInverseHP className={"annuler"} libellé="Annuler" onClick={onClick}></BoutonJauneInverseHP>
                </ButsForm>
             </div>
        </RowStyledForm>
        </>
    )
}

export default FormOubliHP;
